<template>
  <v-text-field
    :value="getBudget"
    @keydown="keydownBudget($event)"
    @input="inputBudget($event)"
    required
    label="Бюджет"
    class="rounded-lg"
    hide-details
    background-color="white"
    outlined
    maxlength="11"
    :rules="nameRules"
  ></v-text-field>
</template>

<script>
import { getPrice } from '@/core/helpers';

export default {
  name: 'Budget',
  mixins: {

  },
  props: {
    budgetStr: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      budget: '',
    };
  },

  mounted() {
    this.budget = getPrice(this.budgetStr);
  },

  watch: {
    budgetStr(val) {
      this.budget = getPrice(val);
    },
  },

  computed: {
    getBudget() {
      return this.budget;
    },
  },

  methods: {
    keydownBudget(e) {
      const reg = /\d/;
      if (!reg.test(e.key)) {
        if (e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
          e.preventDefault();
        }
      } else if (e.key === '0' && this.budget.length === 0) e.preventDefault();
    },
    inputBudget(e) {
      const str = e.replace(/\s+/g, '');
      this.budget = getPrice(str);
      this.$emit('changeBudget', this.budget.replace(/\s+/g, ''));
    },
  },
};

</script>
