<template>
  <v-text-field
    ref="field"
    v-model="valueMain"
    required
    label="Часы"
    class="rounded-lg input-number-no-arrows"
    hide-details
    background-color="white"
    outlined />
</template>

<script>
export default {
  name: 'PayTime',
  props: {
    value: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      valueMain: '',
    };
  },

  methods: {
    filterValue(value) {
      if (!value) return '';
      return parseInt(value.replace(/\s+/g, ''), 10).toString().substring(0, 3);
    },
  },

  created() {
    this.valueMain = this.filterValue(this.value);
  },

  watch: {
    value(val) {
      this.valueMain = this.filterValue(val);
    },
    valueMain(val) {
      this.$refs.field.internalValue = this.filterValue(val);
      this.$emit('changePayTime', {
        payment: this.valueMain || 0,
        index: this.index,
      });
    },
  },
};

</script>
