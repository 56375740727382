<template>
  <section class="projects fade d-flex flex-column">
    <div class="bslIcons">
      <div class="a"></div>
      <div class="b"></div>
      <div class="c"></div>
    </div>
    <div class="create-project-task-wrapper">
      <div class="create-project-task__header mb-6">
        <div class="create-project-task__title">Добавить заказ</div>

        <v-btn
          class="create-project-task__close"
          icon
          plain
          @click="$router.back()"
        >
          <v-icon
            color="black"
            size="20"
          >mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="project-info">
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          class="mt-8"
        >
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <v-text-field
                v-model="task.name"
                required
                :rules="nameRules"
                label="Название заказа"
                class="rounded-lg"
                hide-details
                background-color="white"
                outlined
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <DatePicker
                placeholder="Начало заказа"
                :value="task.date_start"
                @changeDate="changeDateFirst"
              />

              <div class="ml-1">
                <DatePicker
                  placeholder="Окончание заказа"
                  :value="task.date_end"
                  @changeDate="changeDateSecond"
                />
              </div>

            </div>

            <div class="col-item">
              <Budget
                :budgetStr="task.budget"
                @changeBudget="changeBudget"
              />
            </div>

            <div class="col-item">
              <v-select
                :items="status"
                v-model="task.status"
                label="Статус"
                outlined
                required
                :rules="nameRules"
                hide-details
                background-color="white"
                class="rounded-lg"
              ></v-select>
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-textarea
              outlined
              v-model="task.description"
              name="input-7-4"
              hide-details
              class="rounded-lg"
              background-color="white"
              no-resize
              label="Описание"
              height="135"
              required
              :rules="nameRules"
            ></v-textarea>
          </div>
        </v-form>
      </div>
    </div>

    <SpecialistTime
      v-if="project.specialists && project.specialists.length > 0"
      :team="project.specialists"
      @rates="changeTime"
    />

    <v-btn
      class="white--text btn-create radius-root"
      color="black"
      width="210"
      height="52"
      :disabled="!valid"
      @click="submit()"
    >
      Создать
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { statuses } from '@/core/constants/statuses';
import SpecialistTime from './SpecialistTime.vue';
import Budget from '@/components/Inputs/Budget.vue';
import notification from '@/mixins/notification';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'CreateProjectTask',
  mixins: [notification],
  components: {
    SpecialistTime,
    Budget,
    DatePicker,
  },
  data() {
    return {
      valid: false,
      dataMenuFirst: false,
      dataMenuSecond: false,
      task: {
        title: '',
        description: '',
        status: '',
        manager: '',
        code: '',
        budget: '',
        date_start: '',
        date_end: '',
        specialists: [],
        specialist: [],
      },
      status: statuses,
    };
  },

  computed: {
    ...mapState('projects', ['project']),
    getProjectSpecialists() {
      return this.project.specialists;
    },
  },
  methods: {
    ...mapActions('projects', ['createProjectTask', 'getProject']),

    checkValid() {
      const isValid = this.$refs.form.validate();
      return new Promise((resolve) => {
        this.isDatesPeriodValid(this.task.date_start, this.task.date_end, () => {
          this.task.date_end = '';
          this.showErrorNotification('Дата окончания заказа введена некорректно');
          resolve(false);
        }, () => {
          resolve(isValid);
        });
      });
    },
    async submit() {
      if (await this.checkValid()) {
        if (this.task.specialists.length === 0) {
          this.task.specialists = this.project.specialists?.map((e) => ({
            project_specialist: e.id,
            codename: e.resource_kind.codename,
            hours_per_task: 0,
          }));
        }
        await this.createProjectTask({
          budget: this.task.budget,
          date_start: this.task.date_start,
          date_end: this.task.date_end,
          description: this.task.description,
          title: this.task.name,
          status: this.task.status,
          id: this.project.id,
          resources: this.task.specialists,
        });
        this.$router.push(`/projects/${this.project.id}`);
      }
    },

    changeDateFirst(val) {
      this.task.date_start = val;
    },

    changeDateSecond(val) {
      this.task.date_end = val;
    },

    changeBudget(val) {
      this.task.budget = val;
    },

    changeTime(data) {
      this.task.specialists = data;
    },
  },
  async created() {
    await this.getProject(this.$route.params.id);
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/bslIcons";

::v-deep .v-text-field fieldset {
  color: #d6d6d6 !important;
}

.btn-create {
  align-self: center;

}

.create-project-task-wrapper {
  margin-bottom: 40px;
}

.create-project-task__header {
  display: flex;
  align-items: center;
}

.create-project-task__title {
  margin-left: 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.create-project-task__close {
  margin-left: auto;
  margin-right: 10px;
}

.project-info {
  border: 1px solid #f1f1f1;
  border-radius: 32px;
  height: 359px;
  background: #f9f9f9;
  & > * {
    padding: 0 32px;
  }
}

.col-item {
  display: flex;
  width: 33%;
}

.modal__sub-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}
</style>
