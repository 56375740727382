export const statuses = [
  {
    text: 'Активный',
    value: 'is_active',
  },
  {
    text: 'На стопе',
    value: 'on_hold',
  },
  {
    text: 'Завершен',
    value: 'completed',
  },
];
