import { render, staticRenderFns } from "./createProjectTask.vue?vue&type=template&id=3473fbfd&scoped=true&"
import script from "./createProjectTask.vue?vue&type=script&lang=js&"
export * from "./createProjectTask.vue?vue&type=script&lang=js&"
import style0 from "./createProjectTask.vue?vue&type=style&index=0&id=3473fbfd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3473fbfd",
  null
  
)

export default component.exports