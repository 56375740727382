<template>
  <div
    v-if="team"
    class="specialist-rate"
  >
    <div class="specialist-rate__resource_type mb-4">Часы на группу сотрудников</div>
    <div class="specialist-rate__content d-flex flex-column justify-space-between">
      <div class="specialist-rate__inputs">
        <v-col
          cols="auto"
          class="fields col-item mb-4"
          v-for="(member, index) in teamLocal"
          :key="index"
        >
          <v-row class="d-flex align-center">
            <v-text-field
              class="position rounded-lg mr-4"
              outlined
              label="Должность"
              item-text="codename"
              item-value="id"
              return-object
              required
              readonly
              hide-details
              :value="member.codename"
            ></v-text-field>

            <OrderTime
              @changePayTime="changePayTime"
              :value="String(member.hours_per_task)"
              :index="index"
            />

            <!-- <v-btn
              icon
              class="ml-4"
              @click="deleteRate(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import OrderTime from '@/components/Inputs/OrderTime.vue';

export default {
  name: 'SpecialistTime',
  components: {
    OrderTime,
  },
  props: {
    team: {
      type: Array,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    teamLocal: [],
    positions: ['Designer', 'iOS', 'QA', 'Back', 'Analist', 'Front', 'Android', 'DevOps'],
  }),

  methods: {
    deleteRate(index) {
      this.teamLocal.splice(index, 1);
      this.$emit('rates', this.teamLocal);
    },
    // changeRates (e, index) {
    //   if (!e.target.value) this.teamLocal[index].hours_per_task = 0;
    //   this.$emit('rates', this.teamLocal)
    // },
    changePayTime(val) {
      this.teamLocal[val.index].hours_per_task = val.payment;
      this.$emit('rates', this.teamLocal);
    },
  },
  mounted() {
    if (this.edit) {
      this.teamLocal = this.team.map((e) => ({
        project_specialist: JSON.parse(JSON.stringify(e.project_specialist)),
        hours_per_task: e.hours_per_task || 0,
        id: e.id,
        codename: e.project_specialist.resource_kind.codename,
      }));
    } else {
      this.teamLocal = this.team?.map((e) => ({
        project_specialist: e.id,
        codename: e.resource_kind.codename,
        hours_per_task: 0,
      }));
    }
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  width: 220px;
  max-width: 220px;
}

.v-text-field {
  width: 220px;
  max-width: 220px;
}

.specialist-rate__resource_type {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.specialist-rate {
  padding: 0 32px;
}

.specialist-rate__inputs {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 30px 20px 0px;
  }
}
</style>
