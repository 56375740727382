<template>
  <v-menu
    ref="menu" v-model="dataMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template class="ml-14" v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="placeholder"
        readonly
        v-bind="attrs"
        v-on="on"
        prepend-inner-icon="mdi-calendar"
        @click:prepend-inner="dataMenu=!dataMenu"
        hide-details
        :required="isRequired"
        :rules="isRequired ? nameRules : []"
        background-color="white"
        outlined
        clearable
        class="rounded-lg" />
    </template>
    <v-date-picker
      v-model="getValue"
      @input="dataMenu = false"
      no-title
      scrollable
      :month-format="getMonthFormat"
      :header-date-format="getHeaderDateFormat"
      locale="ru-Ru"
      :weekday-format="weekDay"
      :first-day-of-week="1">
    </v-date-picker>
  </v-menu>
</template>

<script>
import dateFormats from '@/mixins/dateFormats';

export default {
  name: 'DatePicker',
  mixins: [dateFormats],
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'дата',
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataMenu: false,
    };
  },
  computed: {
    formattedDate: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('changeDate', '');
      },
    },
    getValue: {
      get() {
        return this.getFormattedDate(this.value);
      },
      set(val) {
        this.$emit('changeDate', this.getUnFormattedDate(val));
      },
    },
  },
  methods: {
    weekDay(date) {
      return new Date(date).toLocaleString('ru-RU', { weekday: 'short' });
    },
    getFormattedDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('.');
      return `${year}-${month}-${day}`;
    },
    getUnFormattedDate(date) {
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
